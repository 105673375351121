// tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
})

// remove transition preload
$("body").removeClass("preload");

// Input focus
$(".input-wrap input, .input-wrap textarea").focus(function () {
    $(this).parent(".input-wrap").addClass("_focused");
});
$(".input-wrap input, .input-wrap textarea").focusout(function () {
    $(this).parent(".input-wrap").removeClass("_focused");
});

//Number Separate
function numberWithCommas(number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
}

// tab button change style
$("[rck-tab-btn]").click(function () {
    const getButtonFamily = $(this).attr("rck-tab-btn");

    $("[rck-tab-btn="+getButtonFamily+"]").addClass("border border-secondary text-secondary");
    $("[rck-tab-btn="+getButtonFamily+"]").removeClass("bg-gradient-1 text-white");
    $("[rck-tab-btn="+getButtonFamily+"]").find("svg").removeClass("icon-white");
    $("[rck-tab-btn="+getButtonFamily+"]").find("svg").addClass("icon-secondary"); 

    $(this).addClass("bg-gradient-1 text-white");
    $(this).removeClass("border border-secondary text-secondary");
    $(this).find("svg").removeClass("icon-secondary");
    $(this).find("svg").addClass("icon-white");
});